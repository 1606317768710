/*
    "w-" prefix for all classnames associated with welcome slide
*/

@font-face {
    font-family: 'Posterama';
    src: url("/fonts/Posterama 2001 W04 SemiBold.ttf") format("opentype");
}

.hwelcome-container{
    background-color: #000D1B;
    max-width: 100vw;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 15%;
    position: relative;
}
.hw-background-container{
    position: absolute;
    top:0px;
    left:0px;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
}
.hw-title-section{
    display: flex;
    color:white;
    font-family: 'Posterama';
    font-size: 5vh;
    width: 100%;
    text-align: left;
    position: relative;
    z-index: 100;
    justify-content: space-around;
}




@media only screen and (max-width: 800px) {
    .hwelcome-container{
        justify-content: center;
        padding: 0px;
    }
    .w-title-section{
        margin-left: 0px;
        width: 100%;
        text-align: center;
    }
}