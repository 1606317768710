.sponsor-container {
    border-radius: 12px;
    width: 300px;
    height: 230px;
    margin: 20px 20px 0px 0px;
    background-color: #fff;
    box-shadow: 0px 0px 100px -70px;
    transition: all 220ms ease;
    display: inline-flex;
    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;
    user-select: none;
  }
  .sponsor-container:hover {
    box-shadow: 0px 0px 100px -50px;
  }
  .sponsor-year {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 0.8em;
    opacity: 0.6;
  }
  .sponsor-tier {
    position: absolute;
    bottom: 10px;
    right: 15px;
    width: 25px;
    opacity: 0.6;
    transition: all 120ms ease;
    cursor: pointer;
  }
  .sponsor-container:hover .sponsor-tier {
    opacity: 1;
  }
  .sponsor-tooltip {
    display: none;
    background-color: rgb(22, 22, 22);
    border-radius: 10px;
    text-align: center;
    padding: 5px 10px;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 0.4px;
    font-size: 0.7em;
    position: absolute;
    bottom: -30px;
    right: -50px;
    width: 120px;
    height: 20px;
    line-height: 20px;
    z-index: 100;
  }
  .sponsor-tier:hover .sponsor-tooltip {
    display: block;
    animation: tooltip-animation 200ms;
  }
  @keyframes tooltip-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .sponsor-image {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sponsor-image > img {
    max-width: 75%;
  }
  
  
  