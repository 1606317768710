.faq-container {
  max-width: 100vw;
  padding: 120px 10%;
  background-color: #fafafa;
  position: relative;
}

.faq-title {
  font-family: Nunito;
  text-align: left;
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  color: #0d141bc7;
  margin-bottom: 31px;
}

.faq-additional {
  text-align: left;
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  margin-top: 34px;
  color: #000000;
}

.f-backdrop {
  position: absolute;
  right: -10px;
  top: 240px;
  font-size: 5em;
  font-weight: bold;
  opacity: 0.02;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 5px;
  color: white;
}
