.welcome-container {
    background-color: #000D1B;
    max-width: 100vw;
    min-height: 75vh;
    display: flex;
    align-items: center;
    padding: 100px 15%;
    position: relative;
}

.w-background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 1;
}

.w-title-section {
    color: white;
    width: 30vw;
    text-align: left;
    position: relative;
    z-index: 100;
}

.w-title-text {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 25px;
}

.w-dna-gif {
    margin-left: auto;
    width: 20vw;
    position: relative;
    z-index: 0;
    margin-bottom: 11vh;
}
/* Simplistic and Futuristic Thematic Text */
.thematic-text {
    font-family: 'Roboto', sans-serif; /* Sleek, modern sans-serif font */
    font-size: 2em; /* Balanced size for readability */
    font-weight: 400; /* Light weight for a minimal look */
    color: #ffffff; /* Clean white color */
    letter-spacing: 0.1em; /* Subtle letter spacing for a futuristic feel */
    text-transform: uppercase; /* All caps for a bold statement */
    opacity: 0.85; /* Slight transparency for a soft feel */
    margin-bottom: 25px;
    text-align: center; /* Center the text */
    line-height: 1.4;
}




/* Button styling */
.application-link {
    margin-top: 15px; /* Space between text and button */
    display: flex;
    justify-content: center;
    z-index: 100;
}

.apply-button {
    background: linear-gradient(45deg, #ff7f50, #ffba08); /* Warm gradient for button */
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1.4em;
    font-weight: 600;
    padding: 15px 35px;
    border-radius: 50px;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}

.apply-button:hover {
    transform: scale(1.05); /* Hover effect for button */
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
}




@media only screen and (max-width: 800px) {
    .welcome-container {
        padding-top: 150px;
        padding-bottom: 50px;
        justify-content: flex-start;
    }

    .w-background-container > svg:nth-child(1) {
        top: -10%;
    }

    .w-dna-gif {
        display: none;
    }

    .w-title-section {
        margin-left: 0;
        width: 100vw;
        text-align: center;
    }

    .apply-button {
        font-size: 1.2em;
        padding: 10px 20px;
        width: 80%;
    }

    .thematic-text {
        text-align: center; /* Center text on smaller screens */
    }
}

@media only screen and (max-width: 480px) {
    .apply-button {
        font-size: 1.1em;
        padding: 10px 15px;
    }
}