.separator {
  font-size: 5.5rem;
  display: none;
}

@media only screen and (max-width: 850px) {
  .separator {
    font-size: 3rem;
  }
}
