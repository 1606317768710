.contact-container{
    background-color:#fafafa;
    max-width:100vw;
    max-height: 40vw;
}
.contact-apply-section{
    max-height: 40vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10vh;
    padding-left: 10%;
    padding-right: 10%;
    /* padding-top: 77px; */
}
.contact-information-section{
    max-width: 100vw;
    background: #000D1B;
    display:flex;
    padding:50px 125px 10px;
}
.contact-apply-title{
    text-align: left;
    display: block;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    color: #073766;
}
.contact-apply-button{
    display:block;
}
.contact-information-address{
    font-family: Nunito;
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    opacity: 0.9;
    text-align:left;
    flex:2;
    padding-right:10px;
}
.contact-information-address>span:nth-child(1){
    font-weight: 700;
    display: block;
}
.contact-information-address>span:nth-child(2){
    font-weight: 400;
    display: block;
}
.contact-information-address>span:nth-child(3){
    font-weight: 400;
    display: block;
}
.contact-information-email{
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: block;
    color: #FFFFFF;
    opacity: 0.9;
    flex:2;
    text-align: left;
    padding-right:20px;
}
.contact-information-email>span>a{
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}
.contact-information-social{
    display: block; 
    text-align: left;
}
.contact-information-social>span{
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    opacity: 0.9;
    text-align: left;
}
/* Social media icon styles */
.contact-information-social-media {
    display: flex;
    gap: 20px; /* Space between icons */
    justify-content: center; /* Center icons if there's extra space */
}

/* Social media icons hover effect */
.contact-information-social-media a {
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.contact-information-social-media a:hover {
    transform: scale(1.1); /* Scale up icons slightly on hover */
    filter: drop-shadow(2px 4px 6px black); /* Apply a subtle shadow effect on hover */
}

/* Make sure to link this CSS with your React component by importing it at the top of your component file */

.code-of-conduct{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.4);
    padding-top: 40px;
}
.code-of-conduct>a{
    color: inherit; 
    text-decoration: inherit;
}
@media screen and (max-width: 600px) {
    .contact-apply-section{
     display: flex;
     flex-direction: column;
    }
    .contact-apply-title{
        text-align: center;
        padding-top:0px;
    }
    .contact-apply-button{
        padding-top: 23px;
        padding-bottom: 87px;
    }
    .contact-information-section{
        padding: 40px 56px; 

    }
  }
  @media screen and (max-width: 950px) {
    .contact-information-section{
        display:flex;
        flex-direction: column;
    }
    .contact-information-address{
        padding-bottom: 20px;
    }
    .contact-information-email{
        padding-bottom: 31px;
    }
    .contact-information-social{
        padding-bottom: 44px;
    }

  }
  @media screen and (max-width: 320px) {
    .contact-information-email{
        word-wrap: break-word;
    }
  }
