/* ::-webkit-scrollbar{width:6px; height: 0px;}::-webkit-scrollbar-track{box-shadow:inset 0 0 20px rgba(0,0,0,.3)}::-webkit-scrollbar-thumb{box-shadow:inset 0 0 200px rgba(0,0,0,.5);border-radius:5px;cursor:pointer}::-webkit-scrollbar-thumb:hover{background:#546c77}::-webkit-scrollbar-thumb:active{background:#43565f}
html {
  scroll-behavior: smooth;
} */

body{
  background-color: #000D1B; /* temporary */
}

.Team {
  text-align: center;
  overflow-x: hidden;
}

.Team-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Team-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Team-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
