.blob-cell{
    width: 50vw;
    display: inline-block;
}
.b-background{
    height: 100%;
    background-position: -200px -200px;
}
@media only screen and (max-width: 1200px) {
    .blob-cell{
        width: 10vw;
    }
}
@media only screen and (max-width: 600px) {
    .blob-cell{
        width: 50vw;
    }
}