.about-container{
    max-width: 100vw;
    padding: 10vh 10%;
    background-color: #000D1B;
    position: relative;
}
.a-backdrop{
    position: absolute;
    right:-10px;
    top:240px;
    font-size: 5em;
    font-weight: bold;
    opacity: 0.02;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 5px;
    color:white;
}
.a-intro{
    position: relative;
    display: flex;
    align-items: center;
    color:white;
}
.a-title{
    text-align: left;
    width: 100%;
    text-transform: uppercase;
    font-family: 'Nunito', sans-serif;
    display: inline-block;
    margin-top: 2vh;
    margin-left: -12vw;
    line-height: 35px;
}
.a-title>span:nth-child(1){
    font-size: 2em;
}
.a-title>span:nth-child(3){
    font-size: 2.5em;
    font-weight: bold;
    letter-spacing: 4px;
}
.a-details{
    float: right;
    max-width: 60vw;
    margin-left: 6vw;
    margin-bottom: 1.5vw;
    text-align: right;
    opacity: 0.8;
    font-size: 1.3em;
    letter-spacing: 1px;
    font-weight: 200;
    display: block;
    align-self: flex-end;
}
.a-stories-title{
    font-family: 'Montserrat', sans-serif;
    margin-top: 15vh;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    color:rgba(255, 255, 255, 0.5);
    font-size: 1.2em;
    letter-spacing: 1px;
    display: block;
}
.a-stories{
    white-space: nowrap;
    padding-top:30px;
    overflow-x: scroll;
    width: 90vw;
    text-align: left;
}
.a-stories>svg{
    width: 35vw;
}

@media only screen and (max-width: 1200px) {
    .a-backdrop{
        display: none;
    }
    .a-intro{
        display: block;
    }
    .a-title{
        margin-left: 0px;
        margin-top: -4vh;
        font-size:12px;
        position: absolute;
        top:30%;
        left: 0px;
        width: 100%;
        text-align: center;
        line-height: 28px;
    }
    .a-details{
        max-width: 80vw;
        margin-top: 4vw;
        text-align: center;
        float: none;
    }
    .a-stories-title{
        font-size: 1em;
        margin-top: 10vh;
    }
    .a-stories{
        padding-top: 10px;
    }
}