@font-face {
  font-family: 'Posterama';
  src: url("/fonts/Posterama 2001 W04 SemiBold.ttf") format("opentype");
}

.tickerShell {
  align-self: stretch;
  display: flex;
  /* border: 1px solid white; */
  padding: 60px;
  justify-content: center;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.tickerShell .timeIsUp {
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Your-Preferred-Font', sans-serif; /* Replace with your desired font */
  font-size: 4vw; /* Adjust the font size as needed */
  color: #ffffff;
}


@media only screen and (max-width: 600px) {
  .tickerShell {
    flex-direction: column; /* Stack elements vertically */
    padding: 20px; /* Reduce padding */
    gap: 10px; /* Space out the elements */
  }

}
@media only screen and (max-width: 850px) {
  .tickerShell {
    align-self: stretch;
    display: none;
    padding: 20px;
  }
  .tickerShell .timeIsUp {
    flex: 1;
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
  }
}

@media only screen and (max-width: 300px) {
  .tickerShell {
    align-self:center;
  }
}