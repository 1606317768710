#mlh-trust-badge{
    display:block;
    max-width:100px;
    min-width:60px;
    position:fixed;
    right:50px;
    top:0;
    width:10%;
    z-index:10000
}

.mlh{
    width:100%
}