/* Ensuring the body has no default margin */
body {
  margin: 0;
  padding: 0;
}

/* Adjust the overall container padding and settings */
.highlight-container {
  padding: 10px;
  justify-content: center;
  align-items: center;
}

/* Media container flex adjustments */
.media-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: 20px auto;
  flex-wrap: wrap;
}

/* Fix for any specific left skew issue */
@media (max-width: 768px) {
  .highlight-container, .media-container {
      padding-left: 0;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%; /* Ensures full width and centered content */
  }

  .video-container, .photo-carousel {
      padding: 0; /* Removes padding that could affect alignment */
  }
}

/* Additional adjustments for executive blocks on mobile */
.executive-info-block {
  margin: 0 auto;
  width: 90%;
}

/* Ensure that all elements inside the container are aligned properly */
* {
  box-sizing: border-box; /* Include padding and borders in the element's total width and height */
}

.highlight-container {
  background: linear-gradient(135deg, #0a0f0d 0%, #003973 100%);
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.event-logo {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}

.logo {
  max-width: 200px;
  height: auto;
}

.video-container, .photo-carousel {
  flex: 1;
  flex-basis: 45%; /* Adjust the width to 45% */
  margin: 10px;
  height: auto; /* Maintain aspect ratio */
}

.event-video {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.event-photo {
  width: 100%;
  height: 350px; /* Set a fixed height slightly smaller than the video */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  object-fit: cover; /* Maintain aspect ratio by covering the container */
}

.media-description {
  font-size: 0.9em;
  color: #cccccc;
  text-align: center;
  padding-top: 10px;
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.executive-display {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  justify-content: center; /* Centering the grid items */
}

.executive-info-block {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
}

.executive-info-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.7);
}

.executive-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
}

.executive-text {
  margin-top: 10px;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

.prev {
  left: -15px;
}

.next {
  right: -15px;
}

.typewriter-effect {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  margin: 20px 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid orange;
  animation: typing 5s steps(40, end) infinite, blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}

@media (max-width: 768px) {
  .typewriter-effect {
      font-size: 1em; /* Smaller font size to fit more characters */
      animation: typing 3s steps(25, end) infinite, blink-caret 0.75s step-end infinite; /* Adjust step count based on average characters */
  }
}

@media (max-width: 768px) {
  .media-container {
      flex-direction: column;
      width: 98%;
      align-items: center;
  }

  .video-container, .photo-carousel {
      width: 100%;
      margin-bottom: 20px;
  }

  .event-video {
      width: 100%;
      height: auto;
  }

  .event-photo {
      width: 100%;
      height: 200px; /* Reduce height for mobile view */
  }

  .executive-info-block {
      width: 100%;
  }

  .carousel-control {
      display: none; /* Hide buttons on mobile, use touch instead */
  }
}
