@font-face {
    font-family: 'Posterama';
    src: url("/fonts/Posterama 2001 W04 SemiBold.ttf") format("opentype");
}

/* Wrapper div to create the border ring */
.border-ring {
    margin-right:10px;
    margin-left: 10px;
    border-radius: 50%; /* Make the border ring circular */
    padding: 0px; /* Controls the thickness of the border */
    margin-bottom: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: padding 400ms ease;
}
/* When the image (inside member-container) is hovered, change the border-ring padding */
.member-container:hover ~ .border-ring,
.border-ring:hover {
    padding: 5px; /* Smoothly transition to 5px padding*/
 }

/* Specific styles for VP and non-VP borders */
.vp-border {
    border: 5px solid #64B5F5; /* Light blue border for VPs */
}

.non-vp-border {
    border: 5px solid white; /* White border for non-VPs */
}

/* General styling for member container */
.member-container {
    border-radius: 50%; /* Make the container circular */
    height: 370px;
    width: 370px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    transition: all 200ms ease; /* Smooth transition for hover */
}

.member-container > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Make the image circular */
    object-fit: cover; /* Ensures the image covers the circular area without distortion */
    transition: all 200ms ease;
}

/* Circular overlay */
.member-container > div {
    width: 100%;
    height: 100%;
    position: absolute; /* Ensures it covers the entire container */
    top: 0;
    left: 0;
    display: flex; /* Centers content */
    justify-content: center;
    align-items: center;
    opacity: 0; /* Hidden by default */
    background-color: rgba(0, 14, 27, 0.5); /* Semi-transparent overlay */
    border-radius: 50%; /* Make the overlay circular to match the container */
    transition: opacity 200ms ease; /* Smooth fade-in */
    font-family: 'Posterama';
    color: white;
}

/* Apply hover effect on the entire container */
.member-container:hover > div {
    opacity: 1; /* Show overlay on hover */
}

/* Additional styles for text */
.m-position {
    color: #64B5F5;
    font-size: 0.8em;
}

.m-field {
    color: #64B5F5;
    font-size: 0.8em;
}
